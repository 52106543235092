@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,900);
body {
  margin: 0;
  font-family: 'Work Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@keyframes FadeAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

* {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1fb172 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2b74b !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f2b74b !important;
}

::selection {
  background: #1fb172;
  color: white;
  text-shadow: none;
}
::-moz-selection {
  background: #1fb172;
  color: #eee;
  text-shadow: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

body {
  background-color: #11ac69 !important;
}

.btn-white {
  background-color: #fff !important;
  border-color: #1fb172 !important;
  color: #1fb172 !important;
  border-radius: 40px !important;
  font-size: 16px !important;
  min-width: 100px !important;
}

.primary-bg-color {
  background-color: #1fb172;
}

.nav-min-height {
  min-height: 100px;
}

.logo-container {
  background-color: #fff;
  width: 150px;
  height: 100px;
  margin-top: 0px;
  position: absolute;
  top: 0;
  box-shadow: 6px 9px 20px -8px rgba(0, 0, 0, 0.4);
}

.footerlogo-container {
  width: 140px;
  margin-left: 8px;
}

.triangle {
  width: 0px;
  height: 0px;
  display: inline;
  border-style: solid;
  border-width: 20px 75px 0px 75px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  margin-left: 1px;
  top: 100px;
  box-shadow: 6px transparent transparent transparent -8px rgba(0, 0, 0, 0.4);
}

/* 
.navbar-custom {
    color: #CC3333;
    background-color: #9132a8;
    }
 
.btn-space {
    margin-right: 10px;
}
.navbar-brand{
color: #42f5c8;
font-size: 30px;
font-family: "candara";
 
}
a:hover {
  background-color: #ebd234;
}
a{
font-size: 18px;
}
.nav-link{
color: #FFFFFF;
} */
.secondary-bg-color {
  background-color: #11ac69 !important;
}

.text-edr-yellow {
  color: #f2b74b;
}

.bg-edr-yellow {
  background-color: #f2b74b !important;
}

.searchbox {
  /* min-width: 500px;
    max-width: 500px; */
  height: 60px;
  border-radius: 30px;
  border-color: transparent;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 0px;
  width: 550px;
  outline: none;
  padding: 20px;
}

.search-btn {
  border-radius: 50px !important;
  position: absolute !important;
  right: 10px !important;
  z-index: 2 !important;
  top: 5px !important;
  width: 200px !important;
  height: 50px !important;
  cursor: pointer !important;
  transform: translateX(2px) !important;
  font-size: 1em;
}

.buttonIn {
  position: relative;
}

.edr-card {
  border-radius: 20px !important;
  top: -20% !important;
  width: 200px !important;
  height: 240px !important;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.edr-card-text {
  font-size: 8px;
}

.edr-anchor {
  color: #11ac69;
}

.edr-card-image,
.edr-res-card {
  border-radius: 20px;
  object-fit: cover;
  height: 250px;
}

.vaas-res-card {
  border-radius: 20px !important;
  min-height: 300px !important;
  background-color: #fff3dd;
  border-color: transparent;
}

.vaas-card-hover:hover {
  border-color: #fff3dd;
  box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
  border-radius: 20px;
  transform: scale(1.05);
}

.button-hover {
  border-radius: 20px;
}

.button-hover:hover {
  border-color: #fff3dd;
  box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
  border-radius: 20px;
  transform: scale(1.05);
}

.cartDelete:hover {
  background-color: #d49090;
  color: rgb(255, 255, 255);
}

.cartDeleteIcon {
  color: rgb(250, 2, 15);
}

.cartDeleteIcon:hover {
  color: rgba(20, 88, 6, 0.849);
}

.bg-edr-light-green {
  background-color: #b8e6d2;
}

.bg-edr-green {
  background-color: #0f9a5e;
}

.bg-edr-red {
  background-color: red;
}

.bg-edr-grayout {
  filter: alpha(opacity=50);
  opacity: 0.5;
  -moz-opacity: 0.5;
}

.bg-edr-grayout-non-click {
  filter: alpha(opacity=50);
  /* opacity: 0.5; */
  -moz-opacity: 0.5;
  pointer-events: none;
  background-color: red;
}

.btn-explore {
  border-radius: 50px;
  width: 150px;
  height: 50px;
}

.edr-vaas-tag {
  position: relative;
  top: -45%;
  left: 0%;
}

@media (max-width: 768px) {
  .edr-vaas-tag {
    position: inherit;
    top: 0%;
    left: 0%;
  }
}

.vaas-nru-mb {
  margin-bottom: 4%;
  max-width: 420px;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.edr-card-radius {
  border-radius: 20px;
}

.edr-dft-bg-gray {
  background-color: #efefef;
}

.otp-field {
  display: flex;
  text-align: start;
  width: 250px;
  min-width: 200px;
}

.otp-style {
  width: 80% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #fff;
  font-size: 20px;
  text-align: center;
}

.edr-dft-text-gray {
  color: #949494;
  font-size: 14px;
}

.edr-dft-text-yellow {
  color: #f2b74b;
}

.edr-dft-text-orange {
  color: #f28e4b;
}

.edr-dft-add-btn {
  width: 80px;
}

.edr-dft-image-radius {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.edr-dft-delivery-time {
  font-size: 12px;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.width-button {
  width: 30%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-bg {
  background-color: #000000;
}

.newsletter-bg {
  background-color: #4b4b4b;
}

.newsletter-bg:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.newsletter-bg::placeholder {
  color: #ffffff;
  opacity: 1;
}

.newsletter-bg::-ms-input-placeholder {
  color: #ffffff;
}

.newsletter-bg:-ms-input-placeholder {
  color: #ffffff;
}

.footer-list {
  list-style-type: none;
}

.email-input {
  position: relative;
  width: 240px;
}

.email-input input {
  border: none;
  display: block;
  width: auto;
  box-sizing: border-box;
}

.email-input img {
  position: absolute;
  top: 8px;
  right: 10px;
}

.popularity-button {
  background-color: #f2b74b;
  border-radius: 20px;
  border: transparent;
  font-size: 12px;
  width: 100px;
}

.borden-none {
  border: transparent;
}

.prev-button {
  border-radius: 20px;
}

.nav-buttons {
  width: 12px;
  margin-top: -30%;
}

.rs-page {
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.text-edr-secondary {
  color: #11ac69;
}

.fs-small {
  font-size: 10px;
}

.review-card {
  height: 100px;
  width: 100px;
  background-color: #11ac69;
  border-radius: 12px;
}

.review-box {
  background-color: #fff7e9;
  border: transparent;
  border-radius: 12px;
}

.review-text {
  color: #f2b74b;
}

.review-gray-text {
  color: #717171;
}

.review-date {
  font-size: 15px;
}

.save-on-delivery {
  background-color: #ffefd2;
  min-height: 150px;
  max-height: 150px;
}

.edr-sod-tag {
  position: relative;
  top: -45%;
  left: 30%;
}

.top-margin {
  margin-top: 6%;
}

.offer-card {
  margin-left: 10%;
  margin-right: 10%;
}

.offer-code {
  height: 50px;
  width: 300px;
  background-color: #ffffff;
  border: 2px solid #11ac69;
  border-radius: 10px;
  color: #11ac69;
}

.edr-cart-image-radius {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.address-gray {
  width: 100% !important;
}

/* pretty radio */
label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

label > input[type="radio"]:checked + * {
  color: teal;
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: teal;
}

/* basic layout */
fieldset {
  margin: 20px;
  max-width: 400px;
}

label > input[type="radio"] + * {
  display: inline-block;
  padding: 0.5rem 1rem;
}

.edr-bg-gray {
  background-color: #ebebeb;
}

.text-line {
  background-color: transparent;
  color: #000000;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #c4c4c4 1px;
  padding: 3px 10px;
}

.text-line-mobile {
  background-color: transparent;
  color: #000000;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  margin-left: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #c4c4c4 1px;
  padding: 3px 35px;
}

.text-edr-primary {
  color: #1fb172;
}

.forgot-password-link {
  color: #f2b74b;
  text-decoration: none;
}

.edr-signup-form-radius {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.gutter-0 {
  --bs-gutter-x: 0;
}

.progress-bar {
  background-color: #20db8a;
}

.profile-edr-card-border {
  border: transparent;
}

/**Bootstrap class overrided **/
.nav-link.active {
  color: #11ac69 !important;
  border: transparent !important;
  border-bottom: 1px solid #11ac69 !important;
}

.nav-tabs {
  border-bottom: transparent;
}

/**Bootstrap class overrided **/

.active-address {
  border: 1px solid #11ac69;
}

@media (max-width: 767px) {
  .edr-signup-form-radius {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
  }

  .edr-dft-image-radius {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.card-body {
  padding: 0.75rem 0.75rem !important;
  min-height: 150px;
}

.no-border {
  border: none !important;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px !important;
  }
}

@media only screen and (max-width: 700px) {
  .ub-w_620px {
    width: 80vw !important;
  }
}

@media only screen and (max-width: 400px) {
  .search-btn {
    border-radius: 50px !important;
    position: absolute !important;
    right: 0px !important;
    z-index: 2 !important;
    width: 150px !important;
    cursor: pointer !important;
    transform: translateX(2px) !important;
    margin-right: 5px;
    font-size: 0.8em !important;
  }

  .locateMe {
    right: 156px !important;
    top: 20px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo-co {
    margin-left: -35px;
    height: 100px;
    width: 100px;
    margin-top: 55px;
  }

  .avatar {
    height: 10px;
    width: 10px;
  }

  .cartIconHeader {
    margin-left: 200px;
  }

  .searchHeader {
    margin-left: -13px;
    width: 100px;
  }

  #textEdit {
    padding: 0.3em;
  }

  #textEdit1 {
    padding: 0.3em;
    font-size: 20px;
  }

  .searchbox {
    width: 250px;
    height: 40px;
  }

  .imageAlign {
    padding: 70px;
    width: 400px;
  }

  #card1 {
    /* margin-left: 56px; */
  }

  #Card2 {
    height: 10em;
    width: 10em;
    margin-left: 50px;
    position: inherit;
  }

  #store1 {
    height: 2.5em;
    width: 8em;
    margin-left: 1em;
    margin: 10px;
  }

  #store2 {
    height: 2.5em;
    width: 8em;
  }

  #textEdit3 {
    text-align: center;
    font-size: 1.5em;
  }

  #card5 {
    height: 400px;
    width: 250px;
  }

  #card6 {
    height: 350px;
    width: 200px;
    margin-right: 100px;
  }

  #email1 {
    margin-left: 2.5em;
  }

  #textEdit6 {
    margin-left: 4.5em;
  }

  #length {
    height: 15cm;
  }

  .textEdit4 {
    margin-left: 0em;
  }

  #textEdit8 {
    margin-left: 6em;
  }

  #dropdownMenuButton {
    background-color: #f2b74b;
    border-radius: 20px;
    border: transparent;
    font-size: 12px;
    width: 100px;
  }

  #cancelOrder {
    border-radius: 100px;
    padding: 1px;
  }
}

@media only screen and (min-width: 250px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 360px) {
  .top-margin {
    margin-top: 5%;
  }

  .edr-reset-card {
    box-shadow: #061a11;
    margin: 140px;
    margin-left: 0px;
    align-self: center;
    border-radius: 20px !important;
    background-color: #fff !important;
    border-color: transparent !important;
    width: 700px;
    height: 500px;
  }

  .addressName {
    margin-left: 22px;
    margin-top: -24px;
  }

  .section-subtitle {
    margin: 50px;
  }
}

@media only screen and (min-width: 500px) {
  .textEdit4 {
    margin-left: 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .searchbox {
    height: 60px;
    border-radius: 30px;
    border-color: transparent;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12);
    width: 480px;
    outline: none;
    padding: 20px;
  }

  .search-btn {
    border-radius: 50px !important;
    position: absolute !important;
    right: 10px !important;
    z-index: 2 !important;
    top: 5px !important;
    cursor: pointer !important;
    transform: translateX(2px) !important;
    margin-right: 1px;
  }

  .edr-resetLogin-card {
    box-shadow: #061a11;
    margin: 90px;
    margin-left: 600px;
    align-self: center;
    border-radius: 20px !important;
    background-color: #fff !important;
    border-color: transparent !important;
    width: 700px;
    height: 600px;
  }
}

.overflowX {
  overflow-x: scroll;
  overflow-y: hidden;
}

.cardPadding {
  padding-top: 65px;
  padding-right: 25px;
}

#dropdownMenuButton {
  background-color: #f2b74b;
  border-radius: 20px;
  border: transparent;
  font-size: 12px;
  width: 110px;
}

#cancelOrder {
  border-radius: 100px;
  padding: 1px;
}

.card-style {
  border-radius: 8px;
  padding: 10px;
}

.imageResponsive {
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.imageResponsivePopUp {
  width: 100%;
  min-width: 320px;
  max-width: 800px;
  border-radius: 8px;
}

.cardHover:hover {
  background-color: #f2b74b;
  border-radius: 0px !important;
}

.cardHover {
  border-radius: 0px !important;
}

.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  /* padding: 0.7rem; */
  width: 30px;
  font-size: 1.5rem;
  background: #f3f3f3;
  color: white;
  border: 0 solid #dbdbdb;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
  background: #0f9a5e;
}

.quantity-input__modifier:hover {
  background: #dadada;
  color: #555555;
}

.quantity-input__modifier--left {
  border-radius: 20px 0 0 20px;
}

.quantity-input__modifier--right {
  border-radius: 0 20px 20px 0;
}

.quantity-input__screen {
  width: 39px;
  padding: 7px;
  font-size: medium;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
  color: white;
  background: #0f9a5e;
}

.edr-pay-card {
  box-shadow: #061a11;
  margin-left: 450px;
  align-self: center;
  border-radius: 20px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  width: 650px;
  height: 450px;
  line-height: 10px;
}

.pay-card-tittle {
  color: #19d181;
  margin: 20px;
  margin-left: 150px;
  align-content: center;
}

.pay-card-tittle1 {
  color: #081811;
  margin-left: 40px;
}

.icon-tick {
  border-color: transparent !important;
  margin-left: 300px;
  margin-top: 40px;
}

.payButton {
  margin: 100px;
  margin-top: 15px;
}

.pay-card-tittleFailed {
  color: #19d181;
  margin: 20px;
}

.paymentCan {
  margin-left: 120px;
}

.pay-card-tittle1Failed {
  color: #081811;
  margin-left: 170px;
}

.icon-tick1 {
  margin-left: 250px;
  margin-top: 40px;
}

.payButton1 {
  margin-left: 225px;
  margin-top: 50px;
}

.card-pay {
  padding-top: 120px;
}

.overflowProfile {
  overflow-y: scroll;
  height: 40vh;
}

.cartProductsList {
  min-height: 234px;
}

.locateMe {
  size: 20px !important;
  position: absolute !important;
  right: 194px;
  top: 10px;
  color: #0f9a5e !important;
  border-radius: 40px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.edr-reset-card {
  box-shadow: #061a11;
  margin: 140px;
  margin-left: 390px;
  align-self: center;
  border-radius: 20px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  width: 700px;
  height: 500px;
}

.icon-lock {
  color: #4b4b4b;
}

.reset-card-tittle {
  align-content: center;
}

.resetButton {
  align-content: center;
  width: 100px;
}

.resetText1 {
  align-content: center;
  font-size: 18px;
  color: rgb(26, 226, 149);
  font-weight: 600;
  margin: 30px;
}

#resetInput {
  transform: translateZ(0);
  cursor: pointer;
  will-change: transform;
  transition: 0.2s ease;
  height: 50px;
}

.resetButton1 {
  align-content: center;
  width: 200px;
  height: 50px;
}

.edr-resetLogin-card {
  box-shadow: #061a11;
  margin: 90px;
  margin-left: 400px;
  align-self: center;
  border-radius: 20px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  width: 700px;
  height: 600px;
}

/* for pagenation */
.pagination {
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  color: #0f9a5e;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.active {
  background-color: #0f9a5e;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: black;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #0f9a5e;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

ul.pagination li.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #0f9a5e;
  border-color: #007bff;
}

.TermsHeder {
  color: rgb(19, 18, 18);
  font-size: 42px;
  margin: 20px;
}

.fontEditTerms {
  font-size: 20px;
  font-family: "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin: 20px;
}

.fontEditContactUs {
  font-size: 20px;
  font-family: "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin: 45px;
}

.headingEdit {
  margin: 20px;
}

.headingEditContact {
  margin: 40px;
}

.contactUs {
  font-size: 22px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 45px;
  line-height: 30px;
}

.PrivacyHeader {
  margin: 20px;
  font-size: 42px;
}

.TextHederprivacy {
  font-size: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 20px;
}

.PrivacyHeader1 {
  margin: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.top-25vh {
  top: 25vh !important;
}

input.searchbox.pac-target-input {
  width: 100%;
}

.vaas-button {
  color: white;
  line-height: 2px;
}

.vaas-button:hover {
  color: white;
}

a {
  text-decoration: none !important;
  color: #121212 !important;
}

a:hover {
  text-decoration: none !important;
}

.btn-white {
  background-color: #fff;
  border-color: #1fb172;
  color: #1fb172;
  border-radius: 40px;
  font-size: 16px;
  min-width: 100px;
}

.primary-bg-color {
  background-color: #1fb172;
}

.nav-min-height {
  min-height: 100px;
}

.logo {
  width: 62%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logo:hover {
  transform: scale(1.1);
}

.triangle {
  width: 0px;
  height: 0px;
  display: inline;
  border-style: solid;
  border-width: 20px 75px 0px 75px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  margin-left: 0px;
  top: 100px;
  box-shadow: 6px transparent transparent transparent -8px rgba(0, 0, 0, 0.4);
}

.secondary-bg-color {
  background-color: #11ac69;
}

.text-edr-yellow {
  color: #f2b74b;
}

.bg-edr-yellow {
  background-color: #f2b74b;
}

.searchbox {
  height: 60px;
  border-radius: 30px;
  border-color: transparent;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 0px;
  width: 100%;
  outline: none;
  padding: 20px;
}

.search-btn {
  border-radius: 50px;
  line-height: 30px;
}

.card-img {
  min-height: 174px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  width: auto;
}

.top-category-img {
  height: auto;
  width: 100%;
  border-radius: 8px;
}

.br-8 {
  border-radius: 8px !important;
}

.br-8 div {
  border-radius: 8px !important;
}

.edr-card-text {
  font-size: 8px;
}

.edr-anchor {
  color: #11ac69;
}

.edr-card-image {
  border-radius: 20px;
}

.edr-res-card {
  border-radius: 20px;
  min-height: 300px;
  background-color: #fff3dd;
  border-color: transparent;
}

.edr-border {
  margin-bottom: 100px;
}

.bg-edr-light-green {
  background-color: #b8e6d2;
}

.btn-explore {
  border-radius: 50px;
  width: 150px;
  height: 50px;
}

.edr-vaas-tag {
  position: relative;
  top: -45%;
  left: 30%;
}

@media (max-width: 768px) {
  .edr-vaas-tag {
    position: inherit;
    top: -45%;
    left: 30%;
  }

  .catButtonContainer {
    display: none !important;
  }
}

.edr-nru-mb {
  margin-bottom: 25%;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.edr-card-radius {
  border-radius: 20px;
}

.edr-dft-bg-gray {
  background-color: #efefef;
}

.edr-dft-text-gray {
  color: #949494;
}

.edr-dft-text-yellow {
  color: #f2b74b;
}

.edr-dft-add-btn {
  width: auto;
}

.edr-dft-image-radius {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.edr-dft-delivery-time {
  font-size: 12px;
}

.edr-bg-yellow {
  background-color: #f2b74b;
}

.pattern-bg {
  padding: 46px 30px;
  background-image: url(/static/media/Pattern.c54ff223.png);
  background-repeat: no-repeat;
}

.app-download-img {
  position: relative;
  top: 10%;
  max-width: 310px;
}

.width-button {
  width: 30%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-bg {
  background-color: #000000;
}

.newsletter-bg {
  background-color: #4b4b4b;
}

.newsletter-bg:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.newsletter-bg::placeholder {
  color: #ffffff;
  opacity: 1;
}

.newsletter-bg::-ms-input-placeholder {
  color: #ffffff;
}

.newsletter-bg:-ms-input-placeholder {
  color: #ffffff;
}

.footer-list {
  list-style-type: none;
}

.email-input {
  position: relative;
  width: 240px;
}

.email-input input {
  border: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.email-input img {
  position: absolute;
  top: 8px;
  right: 10px;
}

.popularity-button {
  background-color: #f2b74b;
  border-radius: 20px;
  border: transparent;
  font-size: 12px;
  width: 100px;
}

.borden-none {
  border: transparent;
}

.prev-button {
  border-radius: 20px;
}

.nav-buttons {
  width: 12px;
  margin-top: -30%;
}

.rs-page {
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.text-edr-secondary {
  color: #11ac69;
}

.fs-small {
  font-size: 10px;
}

.review-card {
  height: 100px;
  width: 100px;
  background-color: #11ac69;
  border-radius: 12px;
}

.review-box {
  background-color: #fff7e9;
  border: transparent;
  border-radius: 12px;
}

.review-text {
  color: #f2b74b;
}

.review-gray-text {
  color: #717171;
}

.review-date {
  font-size: 15px;
}

.save-on-delivery {
  background-color: #ffefd2;
  min-height: 150px;
  max-height: 400px;
}

@media (min-width: 767px) {
  .top-margin {
    margin-top: 0%;
  }
}

@media (max-width: 767px) {
  .save-on-delivery {
    background-color: #ffefd2;
    min-height: 150px;
    max-height: 500px;
  }

  .edr-reset-card {
    box-shadow: #061a11;
    margin: 140px;
    margin-left: 0px;
    align-self: center;
    border-radius: 20px !important;
    background-color: #fff !important;
    border-color: transparent !important;
    width: 700px;
    height: 500px;
  }
}

.edr-sod-tag {
  position: relative;
  top: -30%;
  left: 0%;
}

.offer-card {
  margin-left: 10%;
  margin-right: 10%;
}

.offer-code {
  height: auto;
  width: auto;
  background-color: #ffffff;
  border: 2px solid #11ac69;
  border-radius: 10px;
  color: #11ac69;
}

.edr-cart-image-radius {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* pretty radio */
label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

label > input[type="radio"]:checked + * {
  color: teal;
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: teal;
}

/* basic layout */
fieldset {
  margin: 20px;
  max-width: 400px;
}

label > input[type="radio"] + * {
  display: inline-block;
  padding: 0.5rem 1rem;
}

.edr-bg-gray {
  background-color: #ebebeb;
}

.text-line {
  background-color: transparent;
  color: #000000;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #c4c4c4 1px;
  padding: 3px 10px;
}

.text-edr-primary {
  color: #1fb172;
}

.edr-signup-form-radius {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.gutter-0 {
  --bs-gutter-x: 0;
}

.progress-bar {
  background-color: #20db8a;
}

.profile-edr-card-border {
  border: transparent;
}

/**Bootstrap class overrided **/
.nav-link.active {
  color: #11ac69 !important;
  border: transparent !important;
  border-bottom: 1px solid #11ac69 !important;
}

.nav-tabs {
  border-bottom: transparent;
}

/**Bootstrap class overrided **/

.active-address {
  border: 1px solid #11ac69;
}

.img-background {
  height: -webkit-fill-available;
}

.pickup-card {
  padding: 1rem 0rem;
}

.find_restaurant-button {
  font-size: small;
}

.change-address {
  padding: 0rem 1rem;
}

.carousel-style {
  width: 60%;
}

.carousel-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  margin-right: 2px;
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.restaurant_map-row {
  width: auto;
}

.restaurant_map {
  max-width: 100%;
  height: -webkit-max-content;
  height: max-content;
  width: auto;
}

@media (max-width: 767px) {
  .CategoryText {
    padding-block-end: 0px !important;
  }

  .edr-signup-form-radius {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
  }

  .edr-dft-image-radius {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 0px;
  }
}

.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  /* padding: 0.7rem; */
  width: 30px;
  font-size: 1.5rem;
  background: #f3f3f3;
  color: white;
  border: 0 solid #dbdbdb;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
  background: #0f9a5e;
}

.quantity-input__modifier:hover {
  background: #dadada;
  color: #555555;
}

.quantity-input__modifier--left {
  border-radius: 20px 0 0 20px;
}

.quantity-input__modifier--right {
  border-radius: 0 20px 20px 0;
}

.quantity-input__screen {
  width: 39px;
  padding: 7px;
  font-size: medium;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
  color: white;
  background: #0f9a5e;
}

.catButtonContainer {
  align-items: center;
  height: auto;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 992px) {
  #navbarCollapse1 {
    margin-top: auto !important;
    justify-content: end;
    -webkit-justify-content: flex-end;
    min-height: 82px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .edr-reset-card {
    box-shadow: #061a11;
    margin: 140px;
    margin-left: 0px;
    align-self: center;
    border-radius: 20px !important;
    background-color: #fff !important;
    border-color: transparent !important;
    width: 700px;
    height: 500px;
  }

  .edr-resetLogin-card {
    box-shadow: #061a11;
    margin: 90px;
    margin-left: 0px;
    align-self: center;
    border-radius: 20px !important;
    background-color: #fff !important;
    border-color: transparent !important;
    width: 700px;
    height: 600px;
  }

  #navbarCollapse1 {
    margin-top: 82px;
  }
}

.SeeAllHover {
  color: #11ac69;
  font-size: 25px;
}

.SeeAllHover:hover {
  border-radius: 13%;
  width: 100px;
  background-color: #ffc107;
}

#DivForHoverItem {
  /*just so we can see it*/
  height: 50px;
  width: 200px;
  background-color: transparent;
  opacity: 1;
  transform: translateY(0);
}

/* .resetText1:hover {
  background-color: #ffc107;
  border-radius: 13%;
} */

.CategoryText {
  margin-left: 20px;
  padding-block-start: 50px;
  padding-block-end: 50px;
}

.Out-ofStockText {
  color: #11ac69;
}

.vaas-overlay-restaurant {
  position: absolute;
  top: 10px;
  left: 10px;
}

.vaas-overlay-closed-restaurant {
  position: absolute;
  top: 188px;
  right: 14px;
}

.vaas-overlay-product {
  position: absolute;
  top: 20px;
  right: 24px;
  background: rgb(37, 111, 239);
  color: rgb(255, 255, 255);
  line-height: 1.8rem;
  padding: 0px 0.6rem;
  border-radius: 20px;
}

@media only screen and (max-width: 576px) {
  .vaas-overlay-product {
    top: auto;
    bottom: 20px;
  }
}

.bg-green {
  background: linear-gradient(to bottom, #11ac69 80px, #fff 80px, #fff);
}

.search-text-box {
  min-height: 38px;
}

.homeHeading {
  margin-left: 150px;
}

.rating-hover-style {
  position: absolute;
  top: 214px;
  left: 14px;
  width: 64px;
  height: 24px;
}

.distance-hover-style {
  position: relative;
  top: -38px;
  right: 74px;
  width: 70px;
  height: 24px;
}

.cards {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.25s;
}

.cards:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.img-cards {
  width: 100%;
  height: 200px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  overflow: hidden;
}

.img-cards img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.25s ease;
}

.cards-content {
  padding: 5px;
  text-align: left;
}

.cards-title {
  margin-top: 0px;
  font-weight: 700;
  font-size: 1.65em;
}

.cards-title a {
  color: #000;
  text-decoration: none !important;
}

.cards-read-more {
  border-top: 1px solid #d4d4d4;
}

.cards-read-more a {
  text-decoration: none !important;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.noPadding {
  padding: 0px !important;
}

h2.section-title {
  font-family: "Cabin";
  font-weight: 600;
  font-size: 28px;
  color: #383733;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-left: 100px;
}

.section-subtitle {
  font-size: 22px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

h6.heading {
  font-family: "Great Vibes", serif;
  font-size: 50px;
  color: #0f0d0d;
  margin-bottom: 30px;
  margin-left: 50px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

p.text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #0c0b0b;
  margin: 50px;
  line-height: 32px;
}

.SignInText {
  font-size: large;
  color: #11ac69;
}

.SignUpText {
  font-size: large;
  color: #11ac69;
}

.Fp-top {
  padding-top: 10px;
}

.pace-progress {
  height: 4px !important;
}

.Fp-top {
  padding-top: 10px;
}

.textEdit4 {
  margin-top: -10px;
}

.verticalLine {
  border-left: 2px dashed green;
  height: 50px;
  margin-left: 6px;
}

.horizontalLine {
  border-top: 2px solid black;
}

.horizontalLine2 {
  border-top: 1px dashed black;
}

.profileCard {
  border-color: white;
  min-height: 550px;
}

#instagramlogo {
  margin-left: 3px;
  height: 50px;
  margin-top: -50px;
}

.page-min-height {
  min-height: 67vh;
}

.gotoCartFloating {
  position: fixed;
  right: 0px;
  top: 48vh;
  z-index: 9;
}

#staticBackdrop {
  padding-right: 0px !important;
}

#SignInButton {
  margin-right: 40px;
}

.AboutUs {
  text-align: left;
  margin-left: 50px;
}

.password-link {
  color: #007bff;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.circle {
  width: 50px;
  height: 50px;
  line-height: 40px;
  border-radius: 50%;
  border-color: white;
  padding: 1px;
  border: 4px solid white;
  color: rgb(2, 61, 38);
  text-align: center;
  background: white;
}

.dropdown-menu {
  margin-left: -97px;
}

.dropdown-item:hover {
  color: red;
}

.edr-card-category {
  border-radius: 8px !important;
  top: -20% !important;
  width: 260px !important;
  height: auto !important;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.edr-card-category:hover {
  border-radius: 8px !important;
}

.intl-tel-input {
  width: 54px;
}

.homeSearchBtn {
  width: auto !important;
}

@media only screen and (max-width: 368px) {
  .homeSearchBtn {
    margin: 0 auto;
    position: relative !important;
    width: 180px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .locateMe {
    right: 10px !important;
  }
}

.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 0;
  display: none;
  width: 0px;
}

.intl-tel-input .flag-container {
  padding: 0px !important;
}

.intl-tel-input.iti-container {
  width: 100% !important;
  max-width: 94% !important;
}

.app-link {
  height: 50px;
  margin-right: 16px;
}

.app-link img {
  height: 100%;
  width: auto;
  max-width: 162px;
}
.footer-logo {
  width: 32px;
  height: 32px;
}
.footer-icon-container {
  margin-left: 26px;
}

