.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@keyframes FadeAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

* {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1fb172 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2b74b !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f2b74b !important;
}

::selection {
  background: #1fb172;
  color: white;
  text-shadow: none;
}
::-moz-selection {
  background: #1fb172;
  color: #eee;
  text-shadow: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
